.app-body { 
  background-color: #F8F8F8;
  min-height: 100vh;
  max-height: auto;
}

/* Primary Color */
.primary-color {
  background-color: #2E8BEF !important;
}

.primary-color:disabled {
  background-color: #E0E0E0 !important;
}

/* Table aligment */
@media only screen and (max-width: 768px) {
  .button-column-alignment {
    font-size: 1.5rem !important;
    text-align: left !important;
    padding: .25rem !important;
    margin: .25rem !important;
  }
}

/* Title font */
h4, h5 {
  font-family: Roboto !important;
  font-weight: 700 !important;
}

/* Page alignment */
.page-spacing {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 600px) {
    padding: var(--2, 8px) var(--6, 8px);
  }

  @media (min-width: 601px) and (max-width: 900px) {
    padding: var(--2, 16px) var(--6, 32px);
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    padding: var(--2, 20px) var(--6, 36px);
  }

  @media (min-width: 1201px) {
    padding: var(--2, 24px) var(--6, 48px);
  }
}

/* Secondary elements alignment */
.content-spacing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: var(--none, 0px) var(--none, 0px);
  border-bottom: 1px solid #D9D9D9;
}

.subcontent-spacing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: var(--none, 0px) var(--none, 16px);
}

.table-spacing {
  padding: var(--none, 0px) var(--none, 4px);
}

/* Form Alignment and Position */
.card-spacing {
  display: flex; 
  flex-direction: column;
  gap: 20px;
  padding: var(--2, 0px) var(--6, 48px);
}

.form-position {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-spacing {
  width: 100%; 
  gap: 20px;

  @media screen and (max-width: 600px) {
    gap: 10px;
  }
}

.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-style {
  display: flex;
  width: 50%;
  padding: var(--3, 20px);
  border-radius: 10px;
  border-top: 3px solid #2E8BEF ;
  background:#FFF;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.assessment-title-spacing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.assessment-task-spacing {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.assessment-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--2, 4px) ;
  border-radius: px;
  border-top: 3px solid #2E8BEF ;
  background:#FFF;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.checkbox-alignment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #D9D9D9;
  color: black;
  border-radius: 0.25rem;
  margin: 0.25rem;
}

.checkbox-spacing {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 1.3rem;
}

.errorSelect {
  color: red;
}

#slider {
    height: 0.75rem;
    width: 100% !important;
}

.MuiSlider-mark {
    height: 0.75rem !important;
    width: 0.2rem !important;
}

.MuiSlider-markLabel {
    font-size: 1.3rem !important;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: .1rem;
  padding: 0.15rem;
}